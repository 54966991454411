import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useProductTypesUpload } from "../hooks";

export const BrandProductTypes = () => {
  const [errorMessage, setError] = useState(null);
  const [statusMessage, setStatusMessage] = useState(null);
  const { mutateAsync: uploadProductTypes, isLoading, isError, error } = useProductTypesUpload();
  const onCVSUpload = async (e) => {
    e.preventDefault();
    setError(null);
    const file = e.target.elements[0].files[0];
    const formData = await file.text();
    try {
      const resp = await uploadProductTypes({ productTypesCSV: formData });
      setStatusMessage(`Success: ${resp.message}`);
    } catch (error) {
      if (error.response) {
        setError(`Error: ${error.response.data.error}`);
      } else {
        setError(`Error: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    if (isError) {
      setError(`Error: ${error.message}`);
    }
  }, [isError, error]);

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ pt: 4, mx: 4, flex: 1 }}>
        <h3>Brand Product Types Uploader</h3>
        <p>
          This page is for uploading CSV files of brand product types. The CSV file
          must have a header row with the following columns:
        </p>
        <ul>
          <li>product_type - comma-separated list of product type names to associate, creating if necessary</li>
          <li>brand_name - <strong>single</strong> brand name</li>
        </ul>
        {isLoading && <p>Uploading...</p>}
        {errorMessage && <p style={{ "color": "red" }}>{errorMessage}</p>}
        {statusMessage && <p style={{ "color": "green" }}>{statusMessage}</p>}
        <form onSubmit={onCVSUpload}>
          <TextField type="file" inputProps={{ accept: ".csv" }} />
          <Button type="submit">Upload</Button>
        </form>
      </Box>
    </Box>
  );
};
