import { Box, Typography } from "@mui/material";
import React from "react";
import SimpleMarkdown from "@khanacademy/simple-markdown";
import { COLORS } from "constants/colors";
import { resolveUrl } from "components/ImageUploader/utils";

const DEFAULT_MARGIN = 3;

const rules = {
  ...SimpleMarkdown.defaultRules,
  paragraph: {
    ...SimpleMarkdown.defaultRules.paragraph,
    react: (node, output, state) => (
      <Typography marginBottom={DEFAULT_MARGIN} variant="body1" paragraph>
        {output(node.content, state)}
      </Typography>
    ),
  },
  strong: {
    ...SimpleMarkdown.defaultRules.strong,
    react: (node, output, state) => (
      <Typography variant="body1" fontWeight="medium" component="span">
        {output(node.content, state)}
      </Typography>
    ),
  },
  em: {
    ...SimpleMarkdown.defaultRules.em,
    react: (node, output, state) => (
      <Typography variant="body1" fontStyle="italic">
        {output(node.content, state)}
      </Typography>
    ),
  },
  text: {
    ...SimpleMarkdown.defaultRules.text,
    react: (node) => node.content,
  },
  heading: {
    ...SimpleMarkdown.defaultRules.heading,
    react: (node, output, state) => {
      const variant = `h${parseInt(node.level, 10) + 1}`;
      return (
        <Typography
          fontWeight="bold"
          marginBottom={DEFAULT_MARGIN}
          variant={variant}
        >
          {output(node.content, state)}
        </Typography>
      );
    },
  },
  image: {
    ...SimpleMarkdown.defaultRules.image,
    react: (node) => (
      <Box
        as="img"
        src={node.target}
        alt={node.alt}
        width="100%"
        borderRadius="8px"
      />
    ),
  },
  link: {
    ...SimpleMarkdown.defaultRules.link,
    react: (node, output, state) => (
      <a href={node.target} target="_blank" rel="noreferrer">
        {output(node.content, state)}
      </a>
    ),
  },
  list: {
    ...SimpleMarkdown.defaultRules.list,
    react: (node, output, state) => {
      const variant = node.ordered ? "ol" : "ul";
      return (
        <Box as={variant} sx={{ listStyle: "initial" }}>
          {node.items.map((item, i) => {
            return (
              <Box as="li" key={i} marginBottom={DEFAULT_MARGIN}>
                {output(item, state)}
              </Box>
            );
          })}
        </Box>
      );
    },
  },
  newline: {
    ...SimpleMarkdown.defaultRules.newline,
    react: () => <br />,
  },
  codeBlock: {
    ...SimpleMarkdown.defaultRules.codeBlock,
    react: (node) => <pre>{node.content}</pre>,
  },
};

var parser = SimpleMarkdown.parserFor(rules);
var reactOutput = SimpleMarkdown.reactFor(
  SimpleMarkdown.ruleOutput(rules, "react")
);

export const ContentPreview = ({ body, title, author, imageURL }) => {
  const syntaxTree = parser(body, { rules });
  const children = reactOutput(syntaxTree);

  return (
    <Box>
      <Typography variant="h1" fontWeight="bold">
        {title}
      </Typography>
      <Box
        component="img"
        src={resolveUrl(imageURL, {
          width: 600,
          height: 337.5,
          fit: "crop",
        })}
        alt={title}
        width="100%"
        borderRadius="8px"
        marginY={2}
      />
      <Box
        marginBottom={2}
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box
          width="32px"
          height="32px"
          sx={{ background: COLORS.LIGHT_GRAY }}
          marginRight={2}
          borderRadius={16}
        />
        <Typography variant="h4" fontWeight="bold">
          By {author}
        </Typography>
      </Box>
      {children}
    </Box>
  );
};

export const MarkdownOnlyPreview = ({ body }) => {
  const syntaxTree = parser(body, { rules });
  const children = reactOutput(syntaxTree);

  return <Box>{children}</Box>;
};
