import React, { useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { NumberField } from "@base-ui-components/react/number-field";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { PageHeader, PageLayout, PageMain } from "components/layout/PageLayout";
import {
  CollectiveChallengeGoalType,
  requiredFieldsForCreation,
  useCollectiveChallengeByKey,
  useCollectiveChallengeUpdate,
} from "../hooks";
import { useContent } from "hooks/useContent";
import dayjs from "dayjs";
import { PUBLISHED } from "constants/content";
import { Callout } from "components/Callout";
import CollectiveChallengeVendors from "./collectiveChallengeVendors";
import { SubcategorySelect } from "components/Vendor/SubcategorySelect";
import { COLORS } from "constants/colors";
import { CarbonCategoryColorRadioGroup } from "components/carbonCategoryColorRadioGroup";

export const CollectiveChallenge = () => {
  const { challengeKey: key } = useParams();
  const navigate = useNavigate();
  const [challengeKey] = useState(() => (key === "new" ? undefined : key));
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState("");
  const [articleSearch, setArticleSearch] = React.useState("");
  const { data: _articles } = useContent({
    search: articleSearch,
  });
  const articles = useMemo(() => {
    return (
      _articles?.content?.filter((article) => article.status === PUBLISHED) ||
      []
    );
  }, [_articles]);
  const { data, refetch, isLoading } =
    useCollectiveChallengeByKey(challengeKey);
  const { mutateAsync: updateChallenge } =
    useCollectiveChallengeUpdate(challengeKey);
  const collectiveChallenge = useMemo(
    () =>
      data?.collectiveChallenge ?? {
        key: undefined,
        goalType: undefined,
        title: undefined,
        description: undefined,
        pageDescription: undefined,
        startDate: undefined,
        endDate: undefined,
        goalAmount: undefined,
        currentAmount: undefined,
        shareCopy: undefined,
        coverImageUrl: undefined,
        badgeImageUrl: undefined,
        color: undefined,
        joroSubcategoryId: [],
        rewardEventTypeId: undefined,
        socialProof: undefined,
        articleSlugs: [],
        featuredImages: [],
      },
    [data]
  );
  const [changes, setChanges] = useState({});
  const isEditable = useMemo(() => {
    const endDate = collectiveChallenge?.endDate;
    if (!endDate) {
      return true;
    } else if (dayjs(endDate).isBefore(dayjs())) {
      return false;
    } else {
      return true;
    }
  }, [collectiveChallenge]);

  const hasChanges = useMemo(() => {
    const changeKeys = Object.keys(changes);
    return (
      changeKeys.length > 0 &&
      changeKeys.some((key) => changes[key] !== collectiveChallenge[key])
    );
  }, [changes, collectiveChallenge]);
  const isChallengeUpdating = false;
  const clearChanges = () => setChanges({});

  const handleChanges = (e) => {
    setChanges({
      ...changes,
      [e.target.name]: e.target.value,
    });
  };

  const isCreation = !challengeKey;
  const canSaveCreation = requiredFieldsForCreation.every(
    (field) => changes[field]
  );

  if (isLoading && challengeKey) {
    return <CircularProgress />;
  }

  return (
    <>
      <PageLayout>
        <PageHeader>
          <>
            <Box minWidth="200px">
              {hasChanges && (
                <Box m={0} as="small">
                  (Unsaved changes)
                </Box>
              )}
              <Box m={0} as="h4">
                {!challengeKey
                  ? "Creating New Collective Challenge"
                  : "Editing Collective Challenge:"}{" "}
                {changes?.key ?? collectiveChallenge?.key}
              </Box>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                disabled={
                  !hasChanges ||
                  isChallengeUpdating ||
                  (isCreation && !canSaveCreation)
                }
                onClick={async () => {
                  if (hasChanges) {
                    const startDate =
                      changes?.startDate ?? collectiveChallenge?.startDate;
                    const endDate =
                      changes?.endDate ?? collectiveChallenge?.endDate;
                    if (startDate && dayjs(endDate).isSameOrBefore(startDate)) {
                      setErrorMessage("End date cannot be before start date");
                      setErrorField("endDate");
                      return;
                    } else {
                      try {
                        const resp = await updateChallenge({
                          ...changes,
                        });
                        if (resp?.collectiveChallenge?.key && !challengeKey) {
                          navigate(
                            `/collectivechallenges/${resp.collectiveChallenge.key}`
                          );
                          return;
                        }

                        setChanges({});
                        setErrorMessage(null);
                        setErrorField("");
                        await refetch();
                      } catch (e) {
                        setErrorMessage(e.message);
                      }
                    }
                  }
                }}
                size="small"
              >
                Save
              </Button>
            </Box>
            <Box ml={2}>
              <Button
                variant="contained"
                disabled={!hasChanges || isChallengeUpdating}
                onClick={clearChanges}
                size="small"
              >
                Cancel
              </Button>
            </Box>
            {isChallengeUpdating && <CircularProgress />}
          </>
        </PageHeader>
        <PageMain>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              width: "40vw",
              mb: 2,
            }}
          >
            {errorMessage ? (
              <Callout type="warn">{errorMessage}</Callout>
            ) : !isEditable ? (
              <Callout type="info">Challenge has ended</Callout>
            ) : !challengeKey ? (
              <Callout type="info">
                Save challenge first to select challenge brands
              </Callout>
            ) : null}
            <TextField
              label="Challenge Key"
              name="key"
              value={changes?.key ?? collectiveChallenge?.key}
              onChange={handleChanges}
              error={errorField === "key"}
              onInput={() => {
                setErrorField("");
                setErrorMessage(null);
              }}
              placeholder="Example: NO_FAST_FASHION_FEBRUARY_25"
              InputProps={{
                readOnly: !!collectiveChallenge?.key || !isEditable,
                disabled: !!collectiveChallenge?.key || !isEditable,
              }}
              sx={{
                width: "100%",
                mb: 2,
              }}
            />
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                mb: 2,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                  label="Challenge Start Date (must be first day of a month)"
                  value={dayjs(
                    changes?.startDate ?? collectiveChallenge?.startDate
                  )}
                  format="LL"
                  sx={{
                    pr: 2,
                  }}
                  InputProps={{
                    readOnly: !isEditable,
                    disabled: !isEditable,
                  }}
                  onChange={(e) =>
                    setChanges({
                      ...changes,
                      startDate: e
                        .hour(9)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toDate(),
                    })
                  }
                />
                <DateField
                  label="Challenge End Date (must be first day of the next month)"
                  value={dayjs(
                    changes?.endDate ?? collectiveChallenge?.endDate
                  )}
                  format="LL"
                  InputProps={{
                    readOnly: !isEditable,
                    disabled: !isEditable,
                  }}
                  error={errorField === "endDate"}
                  onChange={(e) => {
                    setErrorField("");
                    setErrorMessage(null);
                    setChanges({
                      ...changes,
                      endDate: e
                        .hour(9)
                        .minute(0)
                        .second(0)
                        .millisecond(0)
                        .toDate(),
                    });
                  }}
                />
              </LocalizationProvider>
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                width: "40vw",
              }}
            >
              <TextField
                label="Cover image"
                name="coverImageUrl"
                value={
                  changes?.coverImageUrl ?? collectiveChallenge?.coverImageUrl
                }
                onChange={handleChanges}
                placeholder="Example: https://joro-assets.s3.amazonaws.com/potato-upload/-1-trend-poll.jpg"
                sx={{ mb: 2 }}
                InputProps={{
                  readOnly: !isEditable,
                  disabled: !isEditable,
                }}
              />
              <Box
                as="img"
                sx={{ ml: 2, mb: 2 }}
                src={
                  changes?.coverImageUrl ?? collectiveChallenge?.coverImageUrl
                }
                alt={"cover image"}
                style={{
                  width: "8vw",
                  height: "8vw",
                  objectFit: "cover",
                }}
              />
            </Box>
            <TextField
              label="Title"
              name="title"
              placeholder="Example: Boycott Fast Fashion"
              value={changes?.title ?? collectiveChallenge?.title}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />

            <Box sx={{ mb: 2 }}>
              <SubcategorySelect
                value={
                  changes.joroSubcategoryId ??
                  collectiveChallenge.joroSubcategoryId ?? []
                }
                multiple
                label="Collective challenge subcategories"
                onChange={handleChanges}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              sx={{
                width: "40vw",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <CarbonCategoryColorRadioGroup
                  label="Challenge completion color"
                  onChange={handleChanges}
                  name="color"
                  value={changes?.color ?? collectiveChallenge?.color}
                />
              </Box>
              <Box sx={{ mb: 2, ml: 20 }}>
                <FormControl>
                  <FormLabel>Goal Type</FormLabel>
                  <RadioGroup
                    placeholder="If spending, progress to goal measured by joined user's transaction sum; if participating, progress to goal measured by number of user's joined challenge."
                    defaultValue={CollectiveChallengeGoalType.Spending}
                    onChange={handleChanges}
                    name="goalType"
                    value={changes?.goalType ?? collectiveChallenge?.goalType}
                  >
                    {Object.keys(CollectiveChallengeGoalType).map(
                      (goalType) => (
                        <FormControlLabel
                          key={goalType}
                          value={goalType.toLowerCase()}
                          control={<Radio />}
                          label={goalType}
                        />
                      )
                    )}
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box sx={{ mb: 2, ml: 20 }}>
                <NumberField.Root>
                  <NumberField.ScrubArea>
                    <FormLabel
                      style={{
                        textColor: !isEditable
                          ? COLORS.LIGHT_GRAY
                          : COLORS.BLACK,
                      }}
                    >
                      Goal Amount
                    </FormLabel>
                  </NumberField.ScrubArea>
                  <NumberField.Group>
                    <NumberField.Decrement />
                    <NumberField.Input
                      name="goalAmount"
                      disabled={!isEditable}
                      placeholder={5000.0}
                      onChange={handleChanges}
                      value={
                        changes?.goalAmount ?? collectiveChallenge?.goalAmount
                      }
                      style={{
                        borderRadius: "4px",
                        padding: "12px",
                      }}
                    />
                    <NumberField.Increment />
                  </NumberField.Group>
                </NumberField.Root>
              </Box>
            </Box>
            <TextField
              label="Card description"
              name="description"
              placeholder="Example: We're keeping our money out of fast fashion and supporting sustainable solutions instead."
              value={changes?.description ?? collectiveChallenge?.description}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            <TextField
              label="Page description"
              name="pageDescription"
              placeholder="Example: This month, our goal is for 1,000 people to boycott 50 of the worst fast fashion brands."
              value={
                changes?.pageDescription ?? collectiveChallenge?.pageDescription
              }
              onChange={handleChanges}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            <TextField
              label="Social proof -- displays before users have joined challenge"
              name="socialProof"
              placeholder="Displays before users have joined challenge. Example: 1k people are boycotting fast fashion"
              value={changes?.socialProof ?? collectiveChallenge?.socialProof}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            <Autocomplete
              multiple
              name="featuredImages"
              freeSolo
              value={
                changes?.featuredImages ??
                collectiveChallenge?.featuredImages ??
                []
              }
              options={[]}
              disableClearable
              onChange={(e, value) => {
                setChanges({
                  ...changes,
                  featuredImages: value,
                });
              }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
              renderTags={(value, getTagProps) =>
                value.map((featuredImage, index) => {
                  const { key, ...tagProps } = getTagProps({ index });
                  return (
                    <>
                      <Chip
                        variant="outlined"
                        label={featuredImage}
                        key={key}
                        {...tagProps}
                        disabled={!isEditable}
                        onDelete={() => {
                          const updatedFeaturedImages =
                            value.filter(
                              (_featuredImage) =>
                                _featuredImage !== featuredImage
                            ) ?? [];
                          setChanges({
                            ...changes,
                            featuredImages: updatedFeaturedImages,
                          });
                        }}
                      />
                      <Box
                        key={featuredImage}
                        as="img"
                        sx={{ ml: 2, mb: 2 }}
                        src={featuredImage}
                        style={{
                          width: "8vw",
                          height: "8vw",
                          objectFit: "cover",
                        }}
                      />
                    </>
                  );
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Featured Images"
                  sx={{ mb: 4 }}
                />
              )}
            />
            <Autocomplete
              multiple
              sx={{ mb: 4 }}
              id="articles"
              inputValue={articleSearch}
              onInputChange={(event, newInputValue) => {
                setArticleSearch(newInputValue);
              }}
              options={articles}
              value={
                changes?.articleSlugs
                  ? articles?.filter((content) =>
                    changes?.articleSlugs?.includes(content.slug)
                  )
                  : collectiveChallenge?.articleSlugs
                    ? articles?.filter((content) =>
                      collectiveChallenge?.articleSlugs?.includes(content.slug)
                    )
                    : []
              }
              disableClearable
              onChange={(e, value, reason, details) => {
                let articleSlugs =
                  changes?.articleSlugs ?? collectiveChallenge?.articleSlugs ?? [];
                if (reason === "selectOption") {
                  articleSlugs.push(details.option.slug);
                } else {
                  articleSlugs = articleSlugs?.filter(
                    (slug) => slug !== details.option.slug
                  );
                }
                setChanges({
                  ...changes,
                  articleSlugs: articleSlugs,
                });
              }}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Featured articles"
                />
              )}
            />
            <TextField
              label="Share copy"
              name="shareCopy"
              placeholder="Example: Join me on Commons! We're doing a collective challenge to boycott fast fashion in February."
              value={changes?.shareCopy ?? collectiveChallenge?.shareCopy}
              onChange={handleChanges}
              sx={{ mb: 2 }}
              InputProps={{
                readOnly: !isEditable,
                disabled: !isEditable,
              }}
            />
            {challengeKey ? (
              <CollectiveChallengeVendors challengeKey={challengeKey} />
            ) : null}
          </Box>
        </PageMain>
      </PageLayout>
    </>
  );
};
