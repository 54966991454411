import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, Button, List, Modal, Skeleton, TextField, Typography } from "@mui/material";

import { COLORS } from "constants/colors";
import { useBrandAttributesQuery, useBrandsBrandAttributesQuery, useBrandsBrandAttributesMutation } from "components/BrandAttributes/hooks";



function BrandAttributesCard({ brandsBrandAttr, onDelete }) {
  return <Box key={brandsBrandAttr.id} sx={{
    mb: 1,
    p: 2,
    borderRadius: 2,
    boxShadow: `inset 0px 0px 0px 1px ${COLORS.OFFSET}`,
  }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{brandsBrandAttr.brandAttribute.name}</Typography>
      <Button onClick={() => onDelete(brandsBrandAttr.brandAttribute.id)} startIcon={<DeleteIcon />} />
    </Box>
  </Box>;
}

function AttributeOption({ attribute }) {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between"
      sx={{ width: "100%" }}
      title={attribute.name}>
      <Typography sx={{ flex: 1 }}>{attribute.name}</Typography>
      {attribute.icon && <img src={attribute.icon} alt={attribute.name} style={{ width: 24, height: 24 }} />}
    </Box>
  );
}

const BrandAttributes = ({ brandId }) => {
  const { isLoading: currentAttributesLoading, error: currentAttributesError, data: currentAttributes } = useBrandsBrandAttributesQuery(brandId);
  const { isLoading: attributesLoading, error: attributesError, data: brandAttributes } = useBrandAttributesQuery();
  const { create, deleteRecord } = useBrandsBrandAttributesMutation(brandId);
  const loading = currentAttributesLoading || attributesLoading;
  const error = currentAttributesError || attributesError;
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const onSelectAttribute = async (attributeId) => {
    await create.mutateAsync({ brandId: brandId, attributeId });
  };
  const availableBrandAttributes = brandAttributes?.filter((attr) => !currentAttributes?.some((existingAttr) => existingAttr.brandAttributeId === attr.id));
  const onDelete = async (id) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };
  const handleConfirmDelete = async () => {
    await deleteRecord.mutateAsync({ attributeId: deleteId });
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };

  return (
    <>
      {loading ?
        <Skeleton variant="rectangular" width="300px" height={400} /> :
        (<>
          <Autocomplete
            options={availableBrandAttributes || []}
            getOptionLabel={(option) => option.name ?? ""}
            style={{ width: 300 }}
            clearOnBlur={true}
            clearOnEscape={true}
            value={value}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={async (_event, value) => {
              if (value) {
                await onSelectAttribute(value.id);
              }
              setInputValue("");
              setValue(null);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <AttributeOption attribute={option} />
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Brand Attribute" variant="outlined"
            />}/>
          {error && <Typography variant="body1" color="error">{error.message}</Typography>}
          <List>
            {currentAttributes?.map((brandsBrandAttr, idx) => (
              <BrandAttributesCard key={brandsBrandAttr.id} brandsBrandAttr={brandsBrandAttr} onDelete={onDelete} idx={idx} />
            ))}
          </List>
        </>)}
      <Modal open={openConfirmDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to remove this brand attribute?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenConfirmDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BrandAttributes;


