import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useHandleActionMutation } from "./hooks";
import { useBrandAttributesQuery } from "components/BrandAttributes/hooks";
import { Box } from "@mui/material";

const reviewStatuses = ["None", "PendingReview", "Reviewing", "Reviewed"];

export default function ActionSelector({ selectedRows }) {
  const [selectedAction, setSelectedAction] = React.useState("");
  const [selectedReviewStatus, setSelectedReviewStatus] = React.useState("");
  const [selectedBrandAttribute, setSelectedBrandAttribute] = React.useState("");
  const [attributeValue, setAttributeValue] = React.useState("");
  const [open, setOpen] = useState(false);
  const handleActionMutation = useHandleActionMutation();
  const { data: brandAttributes } = useBrandAttributesQuery();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handleClose();
    let parameters = {};
    if (selectedAction === "setReviewStatus") {
      parameters = { value: selectedReviewStatus };
    } else if (selectedAction === "addBrandAttributes") {
      parameters = { attributeId: selectedBrandAttribute, value: attributeValue };
    }
    handleActionMutation.mutate({ action: selectedAction, ids: selectedRows, parameters });
  };

  const enableExecuteButton = () => {
    if (!selectedAction || selectedRows.length === 0) { return false; }
    if (selectedAction === "setReviewStatus" && !selectedReviewStatus) { return false; }
    if (selectedAction === "addBrandAttributes" && (!selectedBrandAttribute)) { return false; }
    return true;
  };

  const renderActionFields = () => {
    switch (selectedAction) {
      case "setReviewStatus":
        return (
          <Select value={selectedReviewStatus} displayEmpty onChange={(e) => setSelectedReviewStatus(e.target.value)}>
            <MenuItem value="">- Select Review Status -</MenuItem>
            {reviewStatuses.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        );
      case "addBrandAttributes":
        return (
          <>
            <Select value={selectedBrandAttribute} displayEmpty onChange={(e) => setSelectedBrandAttribute(e.target.value)}>
              <MenuItem value="">- Select Brand Attribute -</MenuItem>
              {brandAttributes?.map((attr) => (
                <MenuItem key={attr.id} value={attr.id}>
                  {attr.name}
                </MenuItem>
              ))}
            </Select>
            <input
              type="text"
              placeholder="Attribute Value (for some attributes)"
              value={attributeValue}
              onChange={(e) => setAttributeValue(e.target.value)}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box display="flex" alignItems="left" flexDirection="column">
      <Box display="flex" alignItems="left" flexDirection="column" p={3} spacing={2}>
        <Select value={selectedAction} placeholder="Select an action" displayEmpty onChange={(e) => setSelectedAction(e.target.value)}>
          <MenuItem value="">- Actions - </MenuItem>
          <MenuItem value="createBrand">Create Brand</MenuItem>
          <MenuItem value="setReviewStatus">Set Review Status</MenuItem>
          <MenuItem value="addBrandAttributes">Add Brand Attributes</MenuItem>
          <MenuItem value="unpublishBrand">Unpublish (remove) brand</MenuItem>
        </Select>
        {renderActionFields()}
      </Box>
      <Button onClick={handleClickOpen} disabled={!enableExecuteButton()}>
        Execute Action
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to execute this action? This will apply to {selectedRows.length} vendors.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
