import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

export const useBrandVendorQuery = ({ page = 1, pageSize = 5, sortKey = "vendorId", sortOrder = "asc", filter = null, vendorName, subcategory }) => {
  return useQuery(
    ["brandVendor", { page, pageSize, sortKey, sortOrder, filter, vendorName, subcategory }],
    async () => {
      const response = await axios.get("/api/brands/vendor_brands", {
        params: { page, pageSize, sortKey, sortOrder, filter, vendorName, subcategory },
      });
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );
};

export const useHandleActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ action, ids, parameters }) => {
      const response = await axios.post("/api/brands/vendor_brands/actions", { action, ids, ...parameters });
      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("brandVendor");
      },
    }
  );
};
