import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../utils";

export const CollectiveChallengeGoalType = {
  Spending: "spending",
  Participation: "participation"
};

export const CollectiveChallengeVendorType = {
  Participating: "Participating",
  Partner: "Partner",
  Excluded: "Excluded"
};

export const useCollectiveChallenges = () =>
  useQuery(["collectiveChallenges"], () =>
    makeApiRequest({
      endpoint: "/api/collective_challenges",
      method: "GET",
      json: true,
    })
  );

export const useCollectiveChallengeByKey = (challengeKey) => {
  return useQuery(["collectiveChallenges", challengeKey], () => {
    return makeApiRequest({
      endpoint: `/api/collective_challenges/${challengeKey}`,
      method: "GET",
      json: true,
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  }, {
    enabled: !!challengeKey
  });
};

export const useCollectiveChallengeUpdate = (challengeKey) => {
  return useMutation((body) => {
    return makeApiRequest({
      endpoint: `/api/collective_challenges${challengeKey ? `/${challengeKey}` : ""}`,
      method: "POST",
      json: true,
      body: {
        ...body,
        rewardEventTypeId: 73,
        currentAmount: 0,
        badgeImageUrl: "https://cmmns.imgix.net/potato-upload/-9-2025-feb-challenge-header.png?fit=crop&auto=format"
      },
      additionalHeaders: {
        "Content-Type": "application/json",
      },
    });
  });
};

export const requiredFieldsForCreation = [
  "key",
  "startDate",
  "endDate",
  "title",
  "description",
  "shareCopy",
  "coverImageUrl",
  "color",
  "goalAmount",
  "goalType",
  "pageDescription",
  "joroSubcategoryId",
];

export const useCollectiveChallengeVendors = ({ challengeKey }) => {
  return useQuery({
    queryKey: ["collectivechallengevendors", { challengeKey }],
    queryFn: async () => {
      return makeApiRequest({
        endpoint: `/api/collective_challenges/${challengeKey}/vendors`,
        method: "GET",
      });
    },
    keepPreviousData: true,
    enabled: !!challengeKey,
  });
};

export const useCollectiveChallengeVendorsMutation = ({ challengeKey }) => {
  const queryClient = useQueryClient();

  return {
    create: useMutation(
      async (data) => {
        return makeApiRequest({
          endpoint: `/api/collective_challenges/${challengeKey}/vendors`,
          method: "POST",
          body: JSON.stringify(data),
          additionalHeaders: {
            "Content-Type": "application/json",
          },
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: "collectivechallengevendors" });
        },
      }
    ),
    deleteRecord: useMutation(
      async ({ challengeVendorId }) => {
        return makeApiRequest({
          endpoint: `/api/collective_challenges/${challengeKey}/vendors/${challengeVendorId}`,
          method: "DELETE",
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: "collectivechallengevendors" });
        },
      }
    ),
  };
};

export const useUpdateCollectiveChallengeVendorsMutation = ({ challengeKey }) => {
  const queryClient = useQueryClient();

  return useMutation(
    async ({ challengeVendorId, data }) => {
      return makeApiRequest({
        endpoint: `/api/collective_challenges/${challengeKey}/vendors/${challengeVendorId}`,
        method: "PUT",
        body: JSON.stringify(data),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "collectivechallengevendors" });
      },
    }
  );
};
