import React, { useMemo } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import { COLORS } from "constants/colors";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useCollectiveChallenges } from "../hooks";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const CollectiveChallengePreview = ({ challengeKey, title, startDate }) => {
  return (
    <Box as="li">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        position="relative"
        py={2}
        px={2}
        sx={{
          borderRadius: "4px",
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box display="flex" flexDirection="column">
            <Link to={`/collectivechallenges/${challengeKey}`}>
              {dayjs(startDate).format("MMMM YYYY")}
            </Link>
            <Box as="small" whiteSpace="nowrap">
              {title}
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );
};

export const CollectiveChallengesList = () => {
  const navigate = useNavigate();
  const { challengeKey } = useParams();
  const { data, isLoading } = useCollectiveChallenges();
  const pastChallenges = useMemo(() => {
    return (
      data?.collectiveChallenges?.filter((challenge) => dayjs(challenge.endDate).isBefore(dayjs())) ??
      undefined
    );
  }, [data?.collectiveChallenges]);
  const currentChallenge = useMemo(() => {
    return data?.collectiveChallenges?.find((challenge) => dayjs(challenge.endDate).isSameOrAfter(dayjs()) && dayjs(challenge.startDate).isSameOrBefore(dayjs())) ??
      undefined;
  }, [data?.collectiveChallenges]);
  const futureChallenges = useMemo(() => {
    return (
      data?.collectiveChallenges
        ?.filter((challenge) => dayjs(challenge.endDate).isSameOrAfter(dayjs()) && challenge.key !== currentChallenge?.key) ?? undefined
    );
  }, [data?.collectiveChallenges, currentChallenge?.key]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        width: "100%",
      }}
    >
      <Box
        sx={{
          position: "sticky",
          top: 0,
          padding: "4px",
          display: "flex",
          height: "100vh",
          minWidth: "330px",
          overflowY: "hkeyden",
          flexDirection: "column",
          width: "20vw",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: COLORS.WHITE,
            borderRadius: "4px",
            position: "relative",
            overflowY: "scroll",
            boxShadow: `inset 0px 0px 0px 1px ${COLORS.LIGHT_GRAY}`,
          }}
          pb={2}
          px={2}
        >
          <Box
            sx={{
              position: "sticky",
              top: 1,
              zIndex: 1,
              backgroundColor: COLORS.WHITE,
            }}
          >
            <Box
              sx={{
                position: "relative",
                backgroundColor: COLORS.WHITE,
                flex: 1,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Typography variant="h2" sx={{ paddingTop: 1, paddingBottom: 2 }}>
                Collective Challenge Management
              </Typography>
              <Button
                sx={{ position: "absolute", right: 0 }}
                size="small"
                variant="contained"
                onClick={() => {
                  navigate("/collectivechallenges/new");
                }}
              >
                +
              </Button>
            </Box>
          </Box>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h3">Current Challenge</Typography>
              <Box display="flex" flexDirection="column" as="ul">
                {currentChallenge ? (
                  <Box position="relative">
                    <CollectiveChallengePreview key={currentChallenge.key} challengeKey={currentChallenge.key} title={currentChallenge.title} startDate={currentChallenge.startDate} />
                    <Box
                      position="absolute"
                      top={12}
                      right={4}
                      sx={{
                        transform: "scale(0.8)",
                      }}
                    >
                      <Chip
                        label="Live"
                        size="small"
                        sx={{
                          backgroundColor: COLORS.SUCCESS,
                        }}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Typography sx={{ pl: 2 }}>
                    No challenge scheduled for the current month.
                  </Typography>
                )}
              </Box>
              <Typography variant="h3">Upcoming Challenges</Typography>
              <Box display="flex" flexDirection="column" as="ul">
                {futureChallenges?.length ? (
                  futureChallenges?.map((challenge) => {
                    return <CollectiveChallengePreview key={challenge.key} challengeKey={challenge.key} title={challenge.title} startDate={challenge.startDate} />;
                  })
                ) : (
                  <Typography sx={{ pl: 2 }}>
                    No future challenges scheduled at this time.
                  </Typography>
                )}
              </Box>
              <Typography variant="h3">Past Challenges</Typography>
              <Box
                display="flex"
                flexDirection="column"
                as="ul"
              >
                {pastChallenges ? (
                  pastChallenges.map((challenge) => {
                    return <CollectiveChallengePreview key={challenge.key} challengeKey={challenge.key} title={challenge.title} startDate={challenge.startDate} />;
                  })
                ) : (
                  <Typography>No past challenges</Typography>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
      <Container>
        <Outlet key={challengeKey} />
      </Container>
    </Box>
  );
};
