import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "components/utils";


export const useProductTypeQuery = (brandId) => {
  return useQuery(["productTypes", { brandId }], () => {
    return makeApiRequest({
      endpoint: "/api/producttypes",
      method: "GET",
    });
  });
};

export const useBrandsByProductTypeQuery = (productTypeId) => {
  return useQuery(["brandsByProductType", { productTypeId }], () => {
    return makeApiRequest({
      endpoint: `/api/producttypes/brands/${productTypeId}`,
      method: "GET",
    });
  },
  {
    enabled: !!productTypeId,
  });
};

export const useProductTypesByBrandQuery = (brandId) => {
  return useQuery(["productTypesByBrand", { brandId }], () => {
    return makeApiRequest({
      endpoint: `/api/producttypes?brandId=${brandId}`,
      method: "GET",
    });
  },
  {
    enabled: !!brandId,
  });
};

export const useBrandsQuery = () => {
  return useQuery(["brands"], () => {
    return makeApiRequest({
      endpoint: "/api/brands",
      method: "GET",
    });
  });
};

export const useProductTypeCRUDMutation = () => {
  const queryClient = useQueryClient();

  return {
    create: useMutation(async (name) => {
      const response = await makeApiRequest({
        endpoint: "/api/producttypes",
        method: "POST",
        body: JSON.stringify({ name }),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
      return response;
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "productTypes" });
      },
    }),
    update: useMutation({
      mutationFn: async ({ id, name, articleSlugs }) => {
        return makeApiRequest({
          endpoint: `/api/producttypes/${id}`,
          method: "PUT",
          body: JSON.stringify({ name, articleSlugs }),
          additionalHeaders: {
            "Content-Type": "application/json",
          },
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "productTypes" });
        queryClient.invalidateQueries({ queryKey: "brandsByProductType" });
      },
    }),
    delete: useMutation(async (id) => {
      return makeApiRequest({
        endpoint: "/api/producttypes",
        method: "DELETE",
        body: JSON.stringify({ id }),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "productTypes" });
      },
    }),
  };
};

export const useProductTypeAssociationMutation = () => {
  const queryClient = useQueryClient();

  return {
    create: useMutation({
      mutationFn: async ({ brandId, productTypeId }) => {
        const response = await makeApiRequest({
          endpoint: "/api/producttypes/associate",
          method: "POST",
          body: JSON.stringify({ brandId, productTypeId }),
          additionalHeaders: {
            "Content-Type": "application/json",
          },
        });
        return response;
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "brandsByProductType" });
      },
    }),
    delete: useMutation(async ({ brandId, productTypeId }) => {
      return makeApiRequest({
        endpoint: "/api/producttypes/dissociate",
        method: "POST",
        body: JSON.stringify({ brandId, productTypeId }),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    }, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "brandsByProductType" });
      },
    }),
  };
};
