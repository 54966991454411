import { makeApiRequest } from "../components/utils";
import { useQuery } from "@tanstack/react-query";

const getGenieCollections = async (props = {}) => {
  const searchQuery = new URLSearchParams(props);
  const hasQuery = searchQuery.toString() !== "";

  return makeApiRequest({
    endpoint: `/api/geniecollections${hasQuery ? `?${searchQuery.toString()}` : ""}`,
    method: "GET",
  });
};

export const useGenieCollections = (props = {}) => {
  return useQuery(
    [
      "GENIE_COLLECTIONS",
      props.search,
      props.limit,
      props.offset,
    ],
    () => getGenieCollections(props),
    {
      cacheTime: 0,
      enabled: props.enabled ?? true,
    }
  );
};
