import React, { useMemo, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  InputLabel,
  List,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { COLORS } from "constants/colors";
import {
  CollectiveChallengeVendorType,
  useCollectiveChallengeVendors,
  useCollectiveChallengeVendorsMutation,
} from "../hooks";
import { useBrandsQuery } from "components/Genie/ProductTypes/hooks";
import {
  BrandOption,
  scoreToStars,
} from "components/Polls/components/pollBrands";

function ChallengeVendorCard({
  vendorName,
  id,
  score,
  onDelete,
  primarySubcategoryId,
}) {
  return (
    <Box
      key={id}
      sx={{
        mb: 1,
        p: 2,
        borderRadius: 2,
        boxShadow: `inset 0px 0px 0px 1px ${COLORS.OFFSET}`,
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h3">{vendorName}</Typography>
        <Button onClick={() => onDelete(id)} startIcon={<DeleteIcon />} />
      </Box>

      <Typography variant="body2">
        {scoreToStars(score, primarySubcategoryId)}
      </Typography>
    </Box>
  );
}

const CollectiveChallengeVendors = ({ challengeKey }) => {
  const {
    isLoading: allBrandsLoading,
    error: allBrandsError,
    data: allBrands,
  } = useBrandsQuery();
  const {
    isLoading: challengeVendorsLoading,
    error: challengeVendorsError,
    data: challengeVendors,
  } = useCollectiveChallengeVendors({ challengeKey });
  const { create, deleteRecord } = useCollectiveChallengeVendorsMutation({
    challengeKey,
  });
  const loading = allBrandsLoading || challengeVendorsLoading;
  const error = allBrandsError || challengeVendorsError;
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  // Initialize state for each type
  const initialState = Object.keys(CollectiveChallengeVendorType).reduce(
    (acc, key) => {
      const lowercaseKey = key.toLowerCase();
      acc[lowercaseKey] = {
        inputValue: "",
        value: "",
      };
      return acc;
    },
    {}
  );

  const [vendorState, setVendorState] = useState(initialState);

  const handleInputChange = (vendorKey, type, newValue) => {
    setVendorState((prev) => ({
      ...prev,
      [vendorKey]: {
        ...prev[vendorKey],
        [type]: newValue,
      },
    }));
  };

  const challengeBrandIds = useMemo(() => {
    return challengeVendors?.collectiveChallengeVendors?.map(
      (challengeVendor) => challengeVendor?.Brand?.id
    );
  }, [challengeVendors]);

  const brandOptions = useMemo(() => {
    // Don't display already selected vendors as an option
    if (challengeBrandIds?.length > 0) {
      return allBrands?.brands?.filter(
        (brand) => !challengeBrandIds?.includes(brand.id)
      );
    } else {
      return allBrands?.brands;
    }
  }, [allBrands, challengeBrandIds]);

  const onSelectBrand = async ({ vendorId, challengeVendorType }) => {
    await create.mutateAsync({
      vendorId: vendorId,
      challengeVendorType,
      challengeKey,
    });
  };

  const onDelete = async (id) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    await deleteRecord.mutateAsync({ challengeVendorId: deleteId });
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };

  return (
    <>
      {loading ? (
        <Skeleton variant="rectangular" width="300px" height={400} />
      ) : (
        <>
          {Object.keys(CollectiveChallengeVendorType).map((vendorType) => {
            const lowercaseType = vendorType.toLowerCase();
            const { inputValue, value } = vendorState[lowercaseType];
            return (
              <Box
                style={{ borderTop: "1px solid black" }}
                sx={{ mt: 2 }}
                key={vendorType}
              >
                <InputLabel sx={{ mb: 2, mt: 1 }}>
                  {vendorType} Brands
                </InputLabel>
                <Autocomplete
                  sx={{ mb: 2 }}
                  options={brandOptions}
                  getOptionLabel={(option) =>
                    option?.Vendor?.vendorName ??
                    `Select ${lowercaseType} vendor`
                  }
                  style={{ width: 300 }}
                  clearOnBlur={true}
                  clearOnEscape={true}
                  value={value}
                  inputValue={inputValue}
                  onInputChange={(_event, newInputValue) => {
                    handleInputChange(
                      lowercaseType,
                      "inputValue",
                      newInputValue
                    );
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?.id === value?.id
                  }
                  onChange={async (_event, value) => {
                    if (value) {
                      await onSelectBrand({
                        vendorId: value.Vendor?.id,
                        challengeVendorType: vendorType,
                      });
                    }
                    handleInputChange(lowercaseType, "inputValue", "");
                    handleInputChange(lowercaseType, "value", null);
                  }}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <BrandOption brand={option} />
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`Add ${vendorType} Brands`}
                      variant="outlined"
                    />
                  )}
                />
                <List>
                  {challengeVendors?.collectiveChallengeVendors
                    ?.filter((cv) => cv.challengeVendorType === vendorType)
                    .map((challengeVendor, idx) => (
                      <ChallengeVendorCard
                        key={challengeVendor.id}
                        id={challengeVendor?.id}
                        vendorName={challengeVendor?.Vendor?.vendorName}
                        onDelete={onDelete}
                        score={
                          challengeVendor?.Vendor?.Brand?.rubricScore?.score
                        }
                        primarySubcategoryId={
                          challengeVendor?.Vendor?.JoroSubcategoriesVendors?.find(
                            (subcatVendor) => subcatVendor.isPrimary
                          )?.joroSubcategoryId
                        }
                        idx={idx}
                      />
                    ))}
                </List>
              </Box>
            );
          })}
          {error && (
            <Typography variant="body1" color="error">
              {error.message}
            </Typography>
          )}
        </>
      )}
      <Modal open={openConfirmDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this challenge vendor?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              onClick={() => {
                setOpenConfirmDialog(false);
              }}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default CollectiveChallengeVendors;
