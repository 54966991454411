import { makeApiRequest } from "components/utils";
import { Rubrics, } from "../../../constants/ingredientGlossaryConstants";
import { useQuery } from "@tanstack/react-query";

export const useIngredientMatchResults = ({ rubric = Rubrics.CHEMICAL, text }) => {
  return useQuery(
    ["ingredientChecker", { rubric, text }],
    () => {
      return makeApiRequest({
        endpoint: "/api/ingredient_glossary/match",
        method: "POST",
        body: JSON.stringify({ rubric, text }),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      enabled: !!text,
    }
  );
};
