import React, { useState } from "react";
import { Container, Stack, Typography, IconButton } from "@mui/material";
import BrandsByProductType from "./components/BrandsByProductType";
import ProductTypesByBrand from "./components/ProductTypesByBrand";
import { ToggleOn, ToggleOff } from "@mui/icons-material";


const ProductTypeManager = () => {
  const [byProductType, setByProductType] = useState(() => {
    const savedValue = localStorage.getItem("byProductType");
    return savedValue ? JSON.parse(savedValue) : false;
  });

  const handleToggle = () => {
    const newValue = !byProductType;
    setByProductType(newValue);
    localStorage.setItem("byProductType", JSON.stringify(newValue));
  };

  return (
    <Container style={{ padding: "20px" }}>
      <Typography variant="h3" gutterBottom>
        Product Type Management
      </Typography>
      <Stack direction="row">
        <IconButton onClick={handleToggle} >
          {byProductType ? <ToggleOn fontSize="large" /> : <ToggleOff fontSize="large" />}
        </IconButton>
        <Typography variant="h6" gutterBottom style={{ marginLeft: "10px" }}>
          {byProductType ? "Brands by Product Type" : "Product Types by Brand"}
        </Typography>
      </Stack>
      {byProductType ? <BrandsByProductType /> : <ProductTypesByBrand />}
    </Container>
  );
};

export default ProductTypeManager;
