import React, { useState, useMemo } from "react";
import { Typography, IconButton, Chip, Autocomplete } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Selector from "./Selector";
import Picklist from "./Picklist";
import { useProductTypeQuery, useBrandsByProductTypeQuery, useBrandsQuery, useProductTypeCRUDMutation, useProductTypeAssociationMutation } from "../hooks";
import { Modal, Box, TextField, Button } from "@mui/material";
import { useWebflowBlogs } from "../../../../hooks/useWebflow";
import { useContent } from "../../../../hooks/useContent";

const brandSort = (brandA, brandB) => {
  if (brandA.name < brandB.name) { return -1; }
  if (brandA.name > brandB.name) { return 1; }
  return 0;
};

const formatBrandName = (brand) => {
  const score = brand?.rubricScore?.score;
  const brandName = brand?.Vendor?.vendorName;
  if (score === undefined || score === null) {
    return brandName;
  } else {
    const stars = Math.round(score);
    const suffix = "★".repeat(stars) + "☆".repeat(5 - stars);
    return `${brand?.Vendor?.vendorName} (${suffix})`;
  }
};

const renderProductType = (productType, onEdit) => (
  <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
    <Typography variant="body1">{productType?.name}</Typography>
    <IconButton
      size="small"
      onClick={(e) => {
        e.stopPropagation();
        onEdit(productType);
      }}
    >
      <EditIcon fontSize="small" />
    </IconButton>
  </Box>
);

const BrandsByProductType = () => {

  const [selectedProductType, setSelectedProductType] = useState(null);
  const [productTypeName, setProductTypeName] = useState("");
  const [articleSlugs, setArticleSlugs] = useState([]);
  const [originalArticleSlugs, setOriginalArticleSlugs] = useState([]);
  const [editProductType, setEditProductType] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // Fetch article slugs from Content and WebflowBlogs
  const { data: webflowData, isLoading: webflowLoading } = useWebflowBlogs();
  const { data: contentData, isLoading: contentLoading } = useContent({ search: "" });

  // Process and merge article slugs from both sources
  const articleSlugOptions = useMemo(() => {
    const webflowSlugs = webflowData?.items
      ? webflowData.items.map(blog => ({
        slug: blog.slug,
        source: "webflow",
        title: blog.name || blog.title
      }))
      : webflowData?.blogs
        ? webflowData.blogs.map(blog => ({
          slug: blog.slug,
          source: "webflow",
          title: blog.name || blog.title
        }))
        : webflowData
          ? Array.isArray(webflowData)
            ? webflowData.map(blog => ({
              slug: blog.slug,
              source: "webflow",
              title: blog.name || blog.title
            }))
            : []
          : [];

    const contentSlugs = contentData?.content?.map(content => ({
      slug: content.slug,
      source: "content",
      title: content.title
    })) || [];

    const allSlugs = [...webflowSlugs, ...contentSlugs];

    const validSlugs = allSlugs.filter(item => item.slug);

    const uniqueSlugs = [];
    const slugMap = {};

    validSlugs.forEach(item => {
      if (!slugMap[item.slug]) {
        slugMap[item.slug] = { sources: [item.source], title: item.title };
        uniqueSlugs.push(item);
      } else {
        if (!slugMap[item.slug].sources.includes(item.source)) {
          slugMap[item.slug].sources.push(item.source);
        }
      }
    });

    // Add source information to each slug
    return uniqueSlugs.map(item => ({
      ...item,
      sources: slugMap[item.slug].sources
    }));
  }, [webflowData, contentData]);

  const onSetSelected = (id) => {
    setSelectedProductType(id);
  };

  const { isLoading: productQueryPending, error: productQueryError, data: productData } = useProductTypeQuery();
  const { data: brandsData } = useBrandsByProductTypeQuery(selectedProductType);
  const { data: allBrandsData } = useBrandsQuery();
  const { create: createProductType, delete: deleteProductType, update: updateProductType } = useProductTypeCRUDMutation();
  const { create: associate, delete: dissociate } = useProductTypeAssociationMutation();
  const [openEntryDialog, setOpenEntryDialog] = useState(false);

  const associateProductTypeWithBrand = async (brandId) => {
    await associate.mutateAsync({
      brandId,
      productTypeId: selectedProductType
    });
  };

  const dissociateProductTypeFromBrand = async ({ id: brandId }) => {
    await dissociate.mutateAsync({ brandId, productTypeId: selectedProductType });
  };

  const onSave = async () => {
    await createProductType.mutateAsync(productTypeName);
    setOpenEntryDialog(false);
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const handleConfirmDelete = async () => {
    await deleteProductType.mutateAsync(selectedProductType);
    setSelectedProductType(null);
    setOpenConfirmDialog(false);
  };

  const remove = () => {
    setOpenConfirmDialog(true);
  };

  const handleEditProductType = (productType) => {
    setEditProductType(productType);
    setProductTypeName(productType.name);
    const currentSlugs = productType.articleSlugs || [];
    setArticleSlugs(currentSlugs);
    setOriginalArticleSlugs(currentSlugs);
    setOpenEditDialog(true);
  };

  // Custom option rendering for Autocomplete
  const renderOption = (props, option) => {
    if (typeof option === "string") {
      return (
        <li {...props}>
          <Box display="flex" alignItems="center">
            {option}
            <Chip
              size="small"
              label="Custom"
              variant="outlined"
              sx={{ ml: 1, height: 20, fontSize: "0.7rem" }}
            />
          </Box>
        </li>
      );
    }

    return (
      <li {...props}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box>
            {option.slug}
            <Typography variant="caption" sx={{ ml: 1, color: "text.secondary" }}>
              {option.title}
            </Typography>
          </Box>
          <Box>
            {option.sources.includes("webflow") && (
              <Chip
                size="small"
                label="Webflow"
                color="primary"
                variant="outlined"
                sx={{ ml: 1, height: 20, fontSize: "0.7rem" }}
              />
            )}
            {option.sources.includes("content") && (
              <Chip
                size="small"
                label="App Content"
                color="secondary"
                variant="outlined"
                sx={{ ml: 1, height: 20, fontSize: "0.7rem" }}
              />
            )}
          </Box>
        </Box>
      </li>
    );
  };

  const getOptionLabel = (option) => {
    if (typeof option === "string") { return option; }
    return option.slug;
  };

  // Helper function to check if arrays are different (handling null/undefined)
  const areArraysDifferent = (array1, array2) => {
    if (!array1 && !array2) { return false; }
    if (!array1 || !array2) { return true; }
    if (array1.length !== array2.length) { return true; }

    // Convert to sets for easier comparison if they contain only primitives
    const set1 = new Set(array1);
    const set2 = new Set(array2);

    if (set1.size !== set2.size) { return true; }

    // Check if all items in set1 exist in set2
    for (const item of set1) {
      if (!set2.has(item)) { return true; }
    }

    return false;
  };

  const handleUpdateProductType = async () => {
    await updateProductType.mutateAsync({
      id: editProductType.id,
      name: productTypeName,
      articleSlugs: articleSlugs
    });
    setOpenEditDialog(false);
  };

  const productTypes = productData?.productTypes ?? [];
  productTypes.sort((a, b) => a.name.localeCompare(b.name));

  const selectedBrands = brandsData?.brands.map((pbi) => ({
    id: pbi.brandId,
    name: formatBrandName(pbi.Brand),
    relationshipId: pbi.id
  })) ?? [];
  selectedBrands.sort(brandSort);

  const availableBrands = selectedProductType ? allBrandsData?.brands?.filter((brand) => !selectedBrands.find((sb) => sb.id === brand.id)).map((brand) => ({
    id: brand?.id,
    name: formatBrandName(brand)
  })) ?? [] : [];
  availableBrands.sort(brandSort);

  return (
    <>
      <Box display="flex" flexDirection="row" gap={2}>
        <Box flex={1}>
          {productTypes.length &&
          <Selector
            title="Product Types"
            options={productTypes}
            onSetSelected={onSetSelected}
            onAdd={() => { setOpenEntryDialog(true); }}
            onSubtract={remove}
            renderItem={(productType) => renderProductType(productType, handleEditProductType)}
          />}
          {
            productQueryPending && <Typography variant="h6">Loading...</Typography>
          }
          {
            productQueryError && <Typography variant="h6">Error: {productQueryError.message}</Typography>
          }
        </Box>
        <Box flex={1}>
          <Picklist
            title="Brands"
            activeList={selectedBrands}
            availableList={availableBrands}
            onActiveItemClick={dissociateProductTypeFromBrand}
            onAvailableItemClick={associateProductTypeWithBrand}
          />
        </Box>
      </Box>
      <Modal open={openEntryDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Add Product Type
          </Typography>
          <TextField
            fullWidth
            label="Product Type Name"
            margin="normal"
            onChange={(e) => setProductTypeName(e.target.value)}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenEntryDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openConfirmDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this product type?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenConfirmDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openEditDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 700,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Edit Product Type
          </Typography>
          <TextField
            fullWidth
            label="Product Type Name"
            margin="normal"
            value={productTypeName}
            onChange={(e) => setProductTypeName(e.target.value)}
          />
          <Autocomplete
            multiple
            freeSolo
            id="article-slugs"
            options={articleSlugOptions}
            value={articleSlugs}
            onChange={(event, newValue) => {
              setArticleSlugs(newValue.map(val => typeof val === "string" ? val : val.slug));
            }}
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            loading={webflowLoading || contentLoading}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const label = typeof option === "string" ? option : option.slug;
                const foundOption = articleSlugOptions.find(o => o.slug === label);
                const isWebflow = foundOption?.sources?.includes("webflow");
                const isContent = foundOption?.sources?.includes("content");

                return (
                  <Chip
                    label={label}
                    {...getTagProps({ index })}
                    key={index}
                    variant={isWebflow || isContent ? "outlined" : "filled"}
                    color={isWebflow && isContent ? "success" : isWebflow ? "primary" : isContent ? "secondary" : "default"}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Article Slugs"
                placeholder="Add article slugs"
                margin="normal"
                fullWidth
                helperText={
                  webflowLoading || contentLoading ?
                    "Loading article slugs..." :
                    "Select from existing slugs or enter custom ones"
                }
              />
            )}
          />

          {/* Legend/Key for color coding */}
          <Box
            sx={{
              mt: 2,
              p: 2,
              bgcolor: "background.paper",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 1
            }}
          >
            <Typography variant="subtitle2" gutterBottom>
              Article Source Key:
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2, alignItems: "center", mt: 1 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label="webflow-example"
                  size="small"
                  color="primary"
                  variant="outlined"
                  sx={{ mr: 1 }}
                />
                <Typography variant="caption">Webflow</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label="content-example"
                  size="small"
                  color="secondary"
                  variant="outlined"
                  sx={{ mr: 1 }}
                />
                <Typography variant="caption">App Content</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label="both-sources-example"
                  size="small"
                  color="success"
                  variant="outlined"
                  sx={{ mr: 1 }}
                />
                <Typography variant="caption">Both Sources</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label="custom-entry"
                  size="small"
                  color="default"
                  variant="filled"
                  sx={{ mr: 1 }}
                />
                <Typography variant="caption">Custom Entry</Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenEditDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleUpdateProductType}
              disabled={
                (!productTypeName.trim()) ||
                (productTypeName === editProductType?.name &&
                !areArraysDifferent(articleSlugs, originalArticleSlugs))
              }
            >
              Update
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BrandsByProductType;
