import React from "react";
import { useState } from "react";
import { Typography, List, ListItem, ListItemText, Button, IconButton, TextField, Stack, } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const Selector = ({ title, options, onSetSelected, onAdd, onSubtract, renderItem }) => {
  const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState("");
  const selectOption = (id) => {
    setSelected(id);
    onSetSelected(id);
  };
  if (!renderItem) {
    renderItem = (option) => <ListItemText primary={option?.name} />;
  }
  const filteredOptions = options.filter((option) => option?.name?.toLowerCase().includes(filter.toLowerCase())) || [];
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography variant="h4">{title}</Typography>
        <TextField
          variant="standard"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Filter"
        />
        <IconButton onClick={() => setFilter("")}>
          <ClearIcon/>
        </IconButton>

      </Stack>
      <div style={{ height: "600px", maxHeight: "600px", overflowY: "auto", border: "1px solid black" }}>
        <List>
          {filteredOptions.map((option, index) => (
            <ListItem
              key={index}
              onClick={() => selectOption(option.id)}
              style={{ backgroundColor: selected === option.id ? "lightblue" : "white" }}
            >
              {renderItem(option)}
            </ListItem>
          ))}
        </List>
      </div>
      <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
        {onAdd && <Button variant="contained" color="primary" onClick={onAdd}>
          +
        </Button>}
        {onSubtract && <Button variant="contained" color="secondary" onClick={onSubtract} disabled={!selected}>
          -
        </Button>}
      </div>
    </>
  );
};

export default Selector;
