import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import { COLORS } from "constants/colors";
import React from "react";

export const CarbonCategoryColorRadioGroup = ({
  onChange,
  name,
  value,
  label,
}) =>
  (<FormControl>
    <FormLabel>{label}</FormLabel>
    <RadioGroup onChange={onChange} name={name} value={value}>
      <FormControlLabel value={COLORS.ELECTRICITY} control={<Radio />} label="Home" />
      <FormControlLabel value={COLORS.FOOD} control={<Radio />} label="Food" />
      <FormControlLabel value={COLORS.TRAVEL} control={<Radio />} label="Transport" />
      <FormControlLabel value={COLORS.SHOPPING} control={<Radio />} label="Goods & Services" />
      <FormControlLabel value={COLORS.FINANCE} control={<Radio />} label="Finance" />
      <FormControlLabel value={COLORS.OFFSET} control={<Radio />} label="Offsets/Other" />
    </RadioGroup>
  </FormControl>
  );
