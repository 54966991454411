import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, Button, List, Modal, Skeleton, TextField, Typography } from "@mui/material";
import { useAvailableBrandRecommendationsQuery, useBrandRelationshipMutation, useBrandRelationshipsQuery } from "hooks/useBrandRelationships";
import { COLORS } from "constants/colors";

const scoreToStars = (score) => {
  if (!score) {
    return "Secondhand";
  }
  const stars = Math.round(score);
  return "★".repeat(stars) + "☆".repeat(5 - stars);
};

function BrandRelationshipCard({ relationship, onDelete }) {
  return <Box key={relationship.id} sx={{
    mb: 1,
    p: 2,
    borderRadius: 2,
    boxShadow: `inset 0px 0px 0px 1px ${COLORS.OFFSET}`,
  }}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h3">{relationship.vendorName}</Typography>
      <Button onClick={() => onDelete(relationship.id)} startIcon={<DeleteIcon />} />
    </Box>

    <Guidance brand={relationship} />
  </Box>;
}

function Guidance({ brand }) {
  const message = (brand.joroSubcategoryKey === "VintageSecondhandAndThrift") ? "Secondhand" : "";
  if (brand.attr) {
    let iconSrc = "https://joro-assets.s3.amazonaws.com/tree.png";
    if (brand.attricon) {
      iconSrc = brand.attricon;
    }
    return <Typography sx={{ flex: 1, textAlign: "right" }}
      title={brand.attr}>{message}<Box component="img" src={iconSrc} alt={ brand.attr} sx={{ height: 20, width: "auto", verticalAlign: "middle", marginLeft: 1 }} /></Typography>;
  }
  return <Typography sx={{ flex: 1, textAlign: "right" }}>{scoreToStars(brand.score)}</Typography>;
}

function BrandOption({ brand }) {
  let message = brand.rowNumber > 0 ? "✨" : " ";
  const title = brand.rowNumber > 0 ? "Similar Brand" : null;
  return (
    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between"
      sx={{ width: "100%" }}
      title={title}>
      <Typography sx={{ flex: 1 }}>{brand.vendorName}</Typography>
      <Typography sx={{ flex: 1, textAlign: "center" }}>
        {message}
      </Typography>

      <Guidance brand={brand} />
    </Box>
  );
}

const BrandRecommendations = ({ brandId }) => {
  const { isLoading: relationshipsLoading, error: relationshipsError, data: relationships } = useBrandRelationshipsQuery(brandId);
  const { isLoading: brandsLoading, error: brandsError, data: brands } = useAvailableBrandRecommendationsQuery(brandId);
  const { create, deleteRecord } = useBrandRelationshipMutation();
  const loading = relationshipsLoading || brandsLoading;
  const error = relationshipsError || brandsError;
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const onSelectBrand = async (targetBrandId) => {
    await create.mutateAsync({ sourceBrandId: brandId, targetBrandId, type: "Recommended Alternative", published: true });
  };

  const onDelete = async (id) => {
    setDeleteId(id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    await deleteRecord.mutateAsync(deleteId);
    setOpenConfirmDialog(false);
    setDeleteId(null);
  };

  return (
    <>
      {loading ?
        <Skeleton variant="rectangular" width="300px" height={400} /> :
        (<>
          <Autocomplete
            options={brands?.suggestedBrands || []}
            getOptionLabel={(option) => option.vendorName ?? ""}
            style={{ width: 300 }}
            clearOnBlur={true}
            clearOnEscape={true}
            value={value}
            inputValue={inputValue}
            onInputChange={(_event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={async (_event, value) => {
              if (value) {
                await onSelectBrand(value.id);
              }
              setInputValue("");
              setValue(null);
            }}
            renderOption={(props, option) => (
              <li {...props}>
                <BrandOption brand={option} />
              </li>
            )}
            renderInput={(params) => <TextField {...params} label="Brand" variant="outlined"
            />}/>
          {error && <Typography variant="body1" color="error">{error.message}</Typography>}
          <List>
            {relationships?.relationships.map((relationship, idx) => (
              <BrandRelationshipCard key={relationship.id} relationship={relationship} onDelete={onDelete} idx={idx} />
            ))}
          </List>
        </>)}
      <Modal open={openConfirmDialog}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2">
            Are you sure you want to delete this brand relationship?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={() => { setOpenConfirmDialog(false); }} sx={{ mr: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleConfirmDelete}>
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default BrandRecommendations;


