import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useState, useRef, useEffect } from "react";
import { Box, Typography, Input } from "@mui/material";
import { useBrandVendorQuery } from "./hooks";
import ActionSelector from "./ActionSelector";

const columns = [
  { field: "id", headerName: "Vendor ID", width: 100, filterable: true, renderCell: (params) => (
    <a href={`/vendors/${params.value}`} target="_blank" rel="noopener noreferrer">
      {params.value}
    </a>
  ) },
  { field: "brandId", headerName: "Brand ID", width: 100, filterable: true, renderCell: (params) => (
    <a href={`https://brands.thecommons.earth/brand-rating/${params.value}`} target="_blank" rel="noopener noreferrer">
      {params.value}
    </a>
  ) },
  { field: "vendorName", headerName: "Vendor Name", width: 200, filterable: false },
  { field: "joroSubcategory", headerName: "Subcategory", width: 200, filterable: false },
  { field: "sustainabilityStatus", headerName: "Sustainability Status", width: 110, filterable: false },
  { field: "reviewStatus", headerName: "Review Status", width: 110, filterable: false },
  { field: "rubricScore", headerName: "Rubric Score", width: 110, sortable: false, filterable: false, valueGetter: (value) => {
    if (!value) {
      return value;
    }
    return value.score;
  }, },
  { field: "firstPublishedAt", headerName: "Published Date", width: 110, filterable: false },
  { field: "numberOfShoppers", headerName: "Number of shoppers", width: 90, filterable: false },
];

export default function BrandVendorGrid() {
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 25,
  });
  const tableRef = React.useRef(null);
  const [sortModel, setSortModel] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });
  const [vendorName, setVendorName] = useState("");
  const [subcategory, setSubcategory] = useState("");
  const vendorNameTimeoutRef = useRef(null);
  const subcategoryTimeoutRef = useRef(null);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const { data, isLoading } = useBrandVendorQuery({
    page: paginationModel.page + 1,
    pageSize: paginationModel.pageSize,
    sortKey: sortModel[0]?.field,
    sortOrder: sortModel[0]?.sort,
    filter: filterModel.items[0],
    vendorName,
    subcategory
  });

  const handleVendorNameChange = (e) => {
    const value = e.target.value;
    clearTimeout(vendorNameTimeoutRef.current);
    vendorNameTimeoutRef.current = setTimeout(() => {
      setVendorName(value);
    }, 500);
  };

  const handleSubcategoryChange = (e) => {
    const value = e.target.value;
    clearTimeout(subcategoryTimeoutRef.current);
    subcategoryTimeoutRef.current = setTimeout(() => {
      setSubcategory(value);
    }, 500);
  };

  useEffect(() => {
    return () => {
      clearTimeout(vendorNameTimeoutRef.current);
      clearTimeout(subcategoryTimeoutRef.current);
    };
  }, []);

  return (
    <Box m={4}>
      <Typography variant="h2" gutterBottom>
      Vendor Brand Search
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" justifyContent="space-between" alignContent="left" flexDirection="row" mb={2} px={8}>
          <Box display="flex" flexDirection="column" alignContent="left">
            <Typography variant="h3" gutterBottom>
          Filters
            </Typography>
            <Input placeholder="Vendor name" onChange={handleVendorNameChange} />
            <Input placeholder="Subcategory" onChange={handleSubcategoryChange} />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignContent="left" flexDirection="row" mb={2} px={8}>
          <Box>
            <ActionSelector selectedRows={rowSelectionModel} />
          </Box>
        </Box>
      </Box>
      <Box >
        <DataGrid
          ref={tableRef}
          rows={data?.rows}
          columns={columns}
          rowCount={data?.totalRows}
          paginationModel={paginationModel}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={setSortModel}
          onFilterModelChange={setFilterModel}
          slots={{ toolbar: GridToolbar }}
          checkboxSelection
          onRowSelectionModelChange={(newValue) => {
            setRowSelectionModel(newValue);
          }}
          rowSelectionModel={rowSelectionModel}
          loading={isLoading}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "skeleton",
            },
          }}
        />
      </Box>
    </Box>
  );
}
