/* eslint-disable no-console */

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../utils";

export const useBrandQuery = (brandId) => {
  return useQuery({
    queryKey: ["brand", brandId],
    queryFn: async () => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}`,
        method: "GET",
      });
      return response;
    },
    keepPreviousData: true,
  });
};

export const useCrawledPagesQuery = (brandId) => {
  return useQuery({
    queryKey: ["crawledPages", brandId],
    queryFn: async () => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}/crawled-pages`,
        method: "GET",
      });
      return response;
    },
  });
};

export const useUpdateVendorMeta = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (updates) => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}/vendor-meta`,
        method: "PUT",
        body: updates,
        json: true,
      });
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["brand", brandId] });
    },
  });
};

export const useStartBrandCrawl = (brandId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await makeApiRequest({
        endpoint: `/api/brands/${brandId}/start-crawl`,
        method: "POST",
        body: { thisIsTheBody: "true" },
        json: true
      });
      if (response.error) {
        throw new Error(response.error);
      }
      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["crawledPages", brandId] });
    },
  });
};
