import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../utils";

export const useBrandAttributesQuery = () => {
  return useQuery({
    queryKey: ["brandAttributes"],
    queryFn: async () => {
      return makeApiRequest({
        endpoint: "/api/brand_attributes",
        method: "GET",
      });
    },
    keepPreviousData: true,
  });
};

export const useUpsertBrandAttributeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      return makeApiRequest({
        endpoint: "/api/brand_attributes",
        method: "POST",
        body: JSON.stringify(data),
        additionalHeaders: {
          "Content-Type": "application/json",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "brandAttributes" });
      },
    }
  );
};

export const useDeleteBrandAttributeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (id) => {
      return makeApiRequest({
        endpoint: `/api/brand_attributes/${id}`,
        method: "DELETE",
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: "brandAttributes" });
      },
    }
  );
};

export const useBrandsBrandAttributesQuery = (brandId) => {
  return useQuery({
    queryKey: ["brandsBrandAttributes", { brandId }],
    queryFn: async () => {
      return makeApiRequest({
        endpoint: `/api/brands/${brandId}/brand_attributes`,
        method: "GET",
      });
    },
    keepPreviousData: true,
  });
};

export const useBrandsBrandAttributesMutation = (brandId) => {
  const queryClient = useQueryClient();

  return {
    create: useMutation(
      async (data) => {
        return makeApiRequest({
          endpoint: `/api/brands/${brandId}/brand_attributes`,
          method: "POST",
          body: JSON.stringify(data),
          additionalHeaders: {
            "Content-Type": "application/json",
          },
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["brandsBrandAttributes", { brandId }] });
        },
      }
    ),
    deleteRecord: useMutation(
      async ({ attributeId }) => {
        return makeApiRequest({
          endpoint: `/api/brands/${brandId}/brand_attributes/${attributeId}`,
          method: "DELETE",
        });
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ["brandsBrandAttributes", { brandId }] });
        },
      }
    )
  };
};
