import React from "react";
import { TableCell } from "@mui/material";

const tableCellStyles = {
  padding: "8px",
  fontSize: "1rem",
};

export const StyledTableCell = (props) => (
  <TableCell sx={tableCellStyles} {...props} />
);
