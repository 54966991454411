import { Box, Button, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useVendorEmailsUpload } from "../hooks";

export const BrandEmail = () => {
  const [errorMessage, setError] = useState(null);
  const [successMessage, setSuccess] = useState(null);
  const { mutateAsync: uploadBrandEmails, isLoading, isError, error } = useVendorEmailsUpload();
  const onCVSUpload = async (e) => {
    e.preventDefault();
    const file = e.target.elements[0].files[0];
    const formData = await file.text();
    try {
      await uploadBrandEmails({ emailsCSV: formData });
      setSuccess("Emails uploaded successfully");
    } catch (error) {
      alert("File upload failed, please see notes");
    }
  };

  useEffect(() => {
    if (isError) {
      setError(`Error: ${error.message}`);
    }
  }, [isError, error]);



  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ pt: 4, mx: 4, flex: 1 }}>
        <h3>Brand Email Uploader</h3>
        <p>
          This page is for uploading CSV files of brand emails. The CSV file
          must have a header row with the following columns:
        </p>
        <ul>
          <li>VendorId</li>
          <li>Email</li>
        </ul>
        {isLoading && <p>Uploading...</p>}
        {errorMessage && <p style={{ "color": "red" }}>{errorMessage}</p>}
        {successMessage && <p style={{ "color": "green" }}>{successMessage}</p>}
        <form onSubmit={onCVSUpload}>
          <TextField type="file" inputProps={{ accept: ".csv" }} />
          <Button type="submit">Upload</Button>
        </form>
      </Box>
    </Box>
  );
};
