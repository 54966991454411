import React, { useMemo } from "react";

import Box from "@mui/material/Box";
import { Autocomplete } from "@mui/material";
import { COLORS } from "../../constants/colors";
import { TextField } from "@mui/material";
import { useSubcategories } from "../../hooks/useSubcategories";

export const SubcategorySelect = ({
  value,
  onChange,
  sx = undefined,
  label = "Primary Subcategory",
  name = "joroSubcategoryId",
  multiple = false,
}) => {
  const { data: subcategoryData } = useSubcategories();

  const subcategoryOptions = useMemo(
    () =>
      subcategoryData?.subcategories?.map((subcategory) => ({
        label: `${subcategory.joroSubcategory} - ${subcategory.TransactionType.transactionType} (id: ${subcategory.id})`,
        value: subcategory.id,
        ...subcategory,
      })) || [],
    [subcategoryData]
  );

  return (
    <Autocomplete
      id="subcategory-select"
      name={name}
      options={subcategoryOptions}
      sx={{ width: 300, ...sx }}
      multiple={multiple}
      value={
        multiple
          ? subcategoryOptions?.filter((option) => value.includes(option.id)) || []
          : subcategoryOptions?.find((option) => option.id === value)?.joroSubcategory ?? null
      }
      onChange={(event, newValue) => {
        onChange({
          target: {
            name,
            value: multiple ? newValue.map((option) => option.id) : newValue?.id,
          },
        });
      }}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
              justifyContent: "flex-start",
              width: "100%",
              alignContent: "flex-start",
              "& > *": {
                width: "100%",
              },
            }}
            {...props}
          >
            <Box as="small" m={0} color={COLORS.GRAY}>
              {option.id}
              {". "}
              {option.TransactionType.transactionType}
            </Box>
            <Box as="h5" m={0}>
              {option.joroSubcategory}
            </Box>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} name="joroSubcategoryId" />
      )}
    />
  );
};
